import { default as ReportsWtFyuv9Mm0Meta } from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/admin/Reports.vue?macro=true";
import { default as CheckoutmG6IgGGVcwMeta } from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/apps/ecommerce/Checkout.vue?macro=true";
import { default as checkoutMixin9G38mE4vBVMeta } from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/apps/ecommerce/checkoutMixin.ts?macro=true";
import { default as LoginaMLLy2lpReMeta } from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/auth/Login.vue?macro=true";
import { default as RegisterOfzK67nIzHMeta } from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/auth/Register.vue?macro=true";
import { default as indexstuau9LrtsMeta } from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/index.vue?macro=true";
import { default as logoutHgCVxcW94zMeta } from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/logout.vue?macro=true";
import { default as UsersWM5EZiyBlNMeta } from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/tests/Users.vue?macro=true";
import { default as AddCreditCardzgOGdjy4FAMeta } from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/user/credit-card/AddCreditCard.vue?macro=true";
import { default as ListCreditCardRm2GwsrVspMeta } from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/user/credit-card/ListCreditCard.vue?macro=true";
import { default as MyProfile7VqkRexRIPMeta } from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/user/MyProfile.vue?macro=true";
import { default as MyPurchasesLEDKYhU2CYMeta } from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/user/MyPurchases.vue?macro=true";
export default [
  {
    name: "admin-Reports",
    path: "/admin/Reports",
    meta: ReportsWtFyuv9Mm0Meta || {},
    component: () => import("/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/admin/Reports.vue").then(m => m.default || m)
  },
  {
    name: "apps-ecommerce-Checkout",
    path: "/apps/ecommerce/Checkout",
    component: () => import("/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/apps/ecommerce/Checkout.vue").then(m => m.default || m)
  },
  {
    name: "apps-ecommerce-checkoutMixin",
    path: "/apps/ecommerce/checkoutMixin",
    component: () => import("/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/apps/ecommerce/checkoutMixin.ts").then(m => m.default || m)
  },
  {
    name: "auth-Login",
    path: "/auth/Login",
    meta: LoginaMLLy2lpReMeta || {},
    component: () => import("/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/auth/Login.vue").then(m => m.default || m)
  },
  {
    name: "auth-Register",
    path: "/auth/Register",
    meta: RegisterOfzK67nIzHMeta || {},
    component: () => import("/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/auth/Register.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "logout",
    path: "/logout",
    component: () => import("/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: "tests-Users",
    path: "/tests/Users",
    component: () => import("/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/tests/Users.vue").then(m => m.default || m)
  },
  {
    name: "user-credit-card-AddCreditCard",
    path: "/user/credit-card/AddCreditCard",
    component: () => import("/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/user/credit-card/AddCreditCard.vue").then(m => m.default || m)
  },
  {
    name: "user-credit-card-ListCreditCard",
    path: "/user/credit-card/ListCreditCard",
    component: () => import("/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/user/credit-card/ListCreditCard.vue").then(m => m.default || m)
  },
  {
    name: "user-MyProfile",
    path: "/user/MyProfile",
    component: () => import("/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/user/MyProfile.vue").then(m => m.default || m)
  },
  {
    name: "user-MyPurchases",
    path: "/user/MyPurchases",
    component: () => import("/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/user/MyPurchases.vue").then(m => m.default || m)
  }
]