import { defineStore } from 'pinia';

interface CreditCard {
    id?: string;
    color: string;
    isDefault: boolean;
    month: string;
    name: string;
    number: string;
    type: string;
    year: string;
    bin: string;
    alias?: string;
}

interface UserState {
    users: Record<string, any> | { loading: boolean };
    creditCards: CreditCard[];
}

export const useUsersStore = defineStore({
    id: 'users',
    state: (): UserState => ({
        users: {},
        creditCards: useAuthStore().userSession?.user?.thirdparty?.credit_card || [],
    }),
    actions: {
        async getUserForEmail(email: string) {
            this.users = { loading: true };
            const baseUrl = useRuntimeConfig().public.apiBase;
            // #TODO: Consultar el email por Body, no por URL
            return fetchAppWrapper.get(`${baseUrl}/base/users/?email=${email}`)
                .then((data)=> {return data })
                .catch(error => {
                    throw error;
                })
        },
        async getUserCreditCards() {
            this.users = { loading: true };
            const baseUrl = useRuntimeConfig().public.apiBase;
            const thirdpartyId = useAuthStore().userSession?.user?.thirdparty?.id;
            // #TODO: Consultar el email por Body, no por URL
            return await fetchWrapper.get(`${baseUrl}/api/v1/management/payments/mercado-pago/${thirdpartyId}/thirdparty_validation/?${Math.random()*90}`)
                .then((data)=> {
                    if (data && data.response && data.response.cards && data.response.id_customer_payments_gateway){
                        this.setCreditCards(data.response.cards);
                        useAuthStore().setUserThirdpartyCreditCards(data.response.cards)
                        useAuthStore().setUserPaymentsGatewayCustomerId(data.response.id_customer_payments_gateway)
                    }
                    return data 
                })
                .catch(error => {
                    throw error;
                })
        },
        async setCreditCards(data: CreditCard[]){
            this.creditCards = data;
            useAuthStore().setUserThirdpartyCreditCards(data)
        },
        async addCreditCard(data: CreditCard){
            const baseUrl = useRuntimeConfig().public.apiBase;
            var tempCreditCards = [...this.creditCards];
            if (data.id){ // edición de tarjeta
                for (let index = 0; index < tempCreditCards.length; index++) {
                    if (tempCreditCards[index].id === data.id){
                        tempCreditCards[index] = {...data};
                    }
                }
            } else { // nueva tarjeta
                data.id = (tempCreditCards.length + 1).toString();
                tempCreditCards.push(data)
            }
            const thirdpartyId = useAuthStore().userSession?.user?.thirdparty?.id;
            return fetchWrapper.patch(`${baseUrl}/api/v1/management/thirdparties/${thirdpartyId}/custom_update/`, 
                { credit_card: tempCreditCards })
                .then(response => {
                    console.log("updating credit-cards...", tempCreditCards)
                    this.setCreditCards(tempCreditCards);
                    return true;
                })
                .catch(error => {
                    console.error("Error updating credit-cards", error)
                    throw error;
                })
        },
        async editCreditCard(data: CreditCard){
            const baseUrl = useRuntimeConfig().public.apiBase;
            var tempCreditCards = [...this.creditCards];
            for (let index = 0; index < tempCreditCards.length; index++) {
                if (tempCreditCards[index].id === data.id) {
                    tempCreditCards[index].isDefault = data.isDefault;
                    tempCreditCards[index].color = data.color;
                    tempCreditCards[index].alias = data.alias;
                } else if (data.isDefault) { // desactiva las restantes si hubo un nuevo default.
                    tempCreditCards[index].isDefault = false;
                }
            }
            const thirdpartyId = useAuthStore().userSession?.user?.thirdparty?.id;
            return fetchWrapper.patch(`${baseUrl}/api/v1/management/thirdparties/${thirdpartyId}/custom_update/`, 
                { credit_card: tempCreditCards })
                .then(response => {
                    this.setCreditCards(response.credit_card);
                    return { success: true, credit_cards: response.credit_card };
                })
                .catch(error => {
                    console.error("error setting default credit-card", error)
                    return { error: true }
                })
        },
        async deleteCreditCard(cardId: string){
            const baseUrl = useRuntimeConfig().public.apiBase;
            var tempCreditCards = [...this.creditCards.filter(item => item.id !== cardId)];
            const thirdpartyId = useAuthStore().userSession?.user?.thirdparty?.id;
            return fetchWrapper.patch(`${baseUrl}/api/v1/management/thirdparties/${thirdpartyId}/custom_update/`, 
                { credit_card: tempCreditCards })
                .then(response => {
                    this.setCreditCards(response.credit_card);
                    return { success: true, credit_cards: response.credit_card };
                })
                .catch(error => {
                    console.error("error deleting credit-card", error)
                    return { error: true }
                })
        }
    },
    getters: {
        getCreditCards(state) {
            return state.creditCards;
        }
    },
});